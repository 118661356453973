import React from 'react'

interface SearchIconProps {
  defaultColor: string
}

const SearchIcon = ({ defaultColor }: SearchIconProps): JSX.Element => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.6929 21.3211L16.3121 15.9403C17.6051 14.3879 18.2499 12.3968 18.1124 10.3812C17.9748 8.36557 17.0654 6.48058 15.5734 5.11836C14.0814 3.75613 12.1217 3.02156 10.1019 3.06746C8.08206 3.11335 6.15771 3.93618 4.72912 5.36476C3.30054 6.79334 2.47772 8.7177 2.43182 10.7375C2.38593 12.7573 3.1205 14.717 4.48272 16.209C5.84494 17.701 7.72994 18.6104 9.74557 18.748C11.7612 18.8856 13.7523 18.2408 15.3046 16.9477L20.6855 22.3286L21.6929 21.3211ZM3.88029 10.9285C3.88029 9.6602 4.25638 8.42039 4.961 7.36585C5.66562 6.31131 6.66712 5.4894 7.83886 5.00405C9.01061 4.5187 10.3 4.39171 11.5439 4.63914C12.7878 4.88657 13.9304 5.4973 14.8272 6.39412C15.724 7.29093 16.3348 8.43354 16.5822 9.67745C16.8296 10.9214 16.7026 12.2107 16.2173 13.3825C15.7319 14.5542 14.91 15.5557 13.8555 16.2603C12.8009 16.9649 11.5611 17.341 10.2928 17.341C8.59271 17.3392 6.96274 16.6629 5.76056 15.4608C4.55838 14.2586 3.88217 12.6286 3.88029 10.9285Z" fill={defaultColor} />
    </svg>
  )
}

export default SearchIcon
