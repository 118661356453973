import React from 'react'

const InfoIcon = (): JSX.Element => {
  return (
    <svg
      width="27"
      height="25"
      viewBox="0 0 27 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8327 17.0583L11.73 16.378C12.2893 16.2617 12.626 16.1455 12.7401 16.0292C12.8657 15.9013 12.9285 15.6862 12.9285 15.3838V10.378C12.9285 10.1338 12.8372 9.95937 12.6545 9.85472C12.4719 9.73844 12.1295 9.62797 11.6272 9.52332L11.73 8.96518C12.9513 8.80239 13.8017 8.72099 14.2811 8.72099L14.4694 8.89541V15.8024C14.4694 15.942 14.4979 16.035 14.555 16.0815C14.6235 16.1164 14.7091 16.1455 14.8118 16.1687L15.6508 16.378L15.5823 17.0583H11.8327ZM14.4865 5.61632C14.4865 5.90702 14.3952 6.1512 14.2126 6.34888C14.0414 6.54656 13.8074 6.64539 13.5106 6.64539C13.2252 6.64539 12.9855 6.55818 12.7915 6.38376C12.6089 6.20934 12.5176 5.97097 12.5176 5.66864C12.5176 5.35469 12.6032 5.10469 12.7744 4.91864C12.957 4.73259 13.2024 4.63957 13.5106 4.63957C14.1612 4.63957 14.4865 4.96515 14.4865 5.61632Z"
        fill="#222222"
      />
      <path
        d="M13.5506 21.7587C12.5018 21.6206 11.4524 21.4872 10.4048 21.3403C10.2052 21.3124 10.0438 21.3237 9.86503 21.4434C8.51077 22.3519 7.14899 23.2479 5.78894 24.1469C5.18325 24.547 4.6979 24.3028 4.6574 23.5703C4.57063 22.0034 4.4879 20.4359 4.41443 18.8678C4.40402 18.6414 4.32939 18.4957 4.157 18.3481C1.8488 16.3687 0.626433 13.8418 0.667506 10.7477C0.694117 8.7452 1.44558 6.97977 2.66621 5.43124C4.44567 3.17393 6.77991 1.78838 9.48785 1.07604C13.5755 0.000425216 17.5185 0.40104 21.2284 2.53212C23.2792 3.71026 24.9106 5.34116 25.8518 7.58543C27.1378 10.6511 26.7676 13.5704 24.9204 16.2822C23.4105 18.4986 21.2898 19.8931 18.8352 20.7862C17.131 21.4066 15.3648 21.6709 13.5605 21.6822C13.557 21.7077 13.5541 21.7332 13.5506 21.7587ZM5.87687 22.5409C6.01398 22.4538 6.10307 22.3987 6.19042 22.3412C7.2994 21.6075 8.40953 20.8762 9.51446 20.136C9.7557 19.9749 9.98941 19.9263 10.2775 19.9926C12.7292 20.5568 15.1739 20.5189 17.5961 19.8255C19.8134 19.1908 21.7659 18.0838 23.3081 16.2994C25.6285 13.6154 25.9796 10.0976 24.2129 7.14926C23.0079 5.13789 21.2441 3.80034 19.1447 2.91733C16.2285 1.69059 13.2203 1.4974 10.1606 2.23581C7.74253 2.81954 5.60151 3.94672 3.94643 5.88697C1.9483 8.22962 1.37038 10.884 2.46027 13.8412C3.01563 15.3476 3.93949 16.5791 5.20119 17.5404C5.43316 17.717 5.63101 17.9036 5.64258 18.2278C5.6582 18.6521 5.68192 19.0758 5.70564 19.4996C5.76059 20.4952 5.81787 21.4896 5.87687 22.5409Z"
        fill="#222222"
      />
    </svg>
  )
}

export default InfoIcon
