export enum StatusProposalEnum {
  ABERTA = 1,
  AGUARDANDO_RETORNO_CLIENTE = 2,
  EM_REVISAO = 3,
  APROVADA = 4,
  REJEITADA = 5,
  CANCELADA = 6,
  CANCELAMENTO_AUTOMATICO = 7
}

export enum StatusProposalStringEnum {
  ABERTA = 'Aberta',
  AGUARDANDO_RETORNO_CLIENTE = 'Ag. Retorno Cliente',
  EM_REVISAO = 'Em Revisao',
  APROVADA = 'Aprovada',
  REJEITADA = 'Rejeitada',
  CANCELADA = 'Cancelada',
  CANCELAMENTO_AUTOMATICO = 'Cancelada',
}
