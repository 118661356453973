import React from 'react'

interface ShipIconProps {
  defaultColor: string
}

const ShipIcon = ({ defaultColor }: ShipIconProps): JSX.Element => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.4106 7.80591C12.0802 7.70044 11.7778 7.602 11.4684 7.50357C10.927 7.33482 10.3856 7.16958 9.84774 7.00083C8.95828 6.7231 8.06883 6.44536 7.17938 6.16411C7.07743 6.13247 6.96493 6.13247 6.86297 6.16411C6.63446 6.23442 6.40243 6.30122 6.17391 6.37153C4.97157 6.74771 3.76923 7.12739 2.56688 7.50357C2.28915 7.59146 2.01493 7.67231 1.7372 7.76021C1.70204 7.77075 1.66688 7.78833 1.61063 7.80942C2.26805 9.12778 2.92196 10.4356 3.57938 11.754C3.66727 11.9333 3.61102 12.1477 3.4493 12.2602L3.42469 12.2778C3.22079 12.4184 2.94305 12.3481 2.83055 12.1266C2.36649 11.209 1.25204 8.99771 0.640321 7.78833C0.517274 7.54224 0.584071 7.2188 0.921571 7.11685C1.83915 6.83911 2.7497 6.5438 3.66376 6.25903C4.74305 5.92153 5.82586 5.58052 6.90516 5.24653C6.97196 5.22544 7.04227 5.22544 7.10907 5.24653C7.75946 5.44692 8.40985 5.65435 9.06024 5.85825C10.3364 6.25903 11.6126 6.6563 12.8923 7.05708C12.9766 7.08521 13.0645 7.10982 13.1489 7.14146C13.3985 7.23638 13.504 7.47192 13.4055 7.72153C13.388 7.77075 13.3634 7.81294 13.3387 7.86216C12.7446 9.05396 11.6337 11.2688 11.1837 12.1653C11.0782 12.3727 10.818 12.4395 10.6247 12.3094C10.6106 12.2989 10.5966 12.2883 10.5825 12.2813C10.4102 12.1618 10.3505 11.9333 10.4454 11.7434C11.0993 10.4321 11.7497 9.1313 12.4106 7.80591Z" fill={defaultColor} />
      <path d="M13.142 12.2602H13.1385C12.808 12.3235 12.5232 12.4747 12.2561 12.6751C12.1225 12.7735 11.9854 12.879 11.8377 12.9458C11.4615 13.1181 11.0889 13.097 10.7338 12.879C10.4877 12.7278 10.2627 12.5345 10.0025 12.4184C9.29238 12.102 8.5998 12.1653 7.95645 12.6118C7.82988 12.6997 7.71035 12.8017 7.57676 12.879C7.37988 12.995 7.17949 13.0548 6.98262 13.0618C6.78574 13.0548 6.58535 12.995 6.38848 12.879C6.25488 12.8017 6.13887 12.6997 6.00879 12.6118C5.36191 12.1653 4.67285 12.102 3.9627 12.4184C3.70254 12.5345 3.47754 12.7278 3.23145 12.879C2.87637 13.097 2.5002 13.1181 2.12754 12.9458C1.97637 12.8755 1.84277 12.7735 1.70918 12.6751C1.44199 12.4782 1.15371 12.327 0.826758 12.2602H0.823242C0.605273 12.2181 0.401367 12.3868 0.401367 12.6083C0.401367 12.9704 0.643945 13.1075 0.742383 13.1286C0.97793 13.1743 1.15371 13.3325 1.34004 13.4696C1.92715 13.9126 2.57051 14.0216 3.27363 13.8106C3.54785 13.7263 3.77988 13.5645 4.00488 13.3888C4.17363 13.2587 4.35293 13.1392 4.56738 13.1075C4.94355 13.0548 5.29512 13.1181 5.60098 13.3677C5.72402 13.4696 5.85762 13.561 5.99473 13.6419C6.3041 13.8317 6.63809 13.9091 6.98262 13.9126C7.33066 13.9126 7.66113 13.8317 7.97051 13.6419C8.10762 13.5575 8.24121 13.4661 8.36426 13.3677C8.67012 13.1181 9.02168 13.0548 9.39785 13.1075C9.6123 13.1392 9.7916 13.2587 9.96035 13.3888C10.1854 13.5645 10.4174 13.7263 10.6916 13.8106C11.3912 14.0251 12.0381 13.9126 12.6252 13.4696C12.808 13.329 12.9838 13.1743 13.2229 13.1286C13.3213 13.1075 13.5639 12.9704 13.5639 12.6083C13.5639 12.3868 13.36 12.2181 13.142 12.2602Z" fill={defaultColor} />
      <path d="M6.99681 1.37935C6.7472 1.37935 6.49408 1.38286 6.24447 1.37935C6.04759 1.37583 5.87884 1.31255 5.79447 1.11216C5.67845 0.841455 5.85775 0.542627 6.15306 0.528564C6.33236 0.521533 6.51166 0.525049 6.69447 0.525049C7.06713 0.525049 7.43978 0.525049 7.81595 0.525049C8.07963 0.525049 8.27299 0.704346 8.26947 0.946924C8.26595 1.14731 8.09369 1.36528 7.83002 1.37935C7.68236 1.38638 7.5347 1.38286 7.38705 1.38286C7.25345 1.38286 7.12689 1.38286 6.99681 1.37935C6.99681 1.38286 6.99681 1.37935 6.99681 1.37935Z" fill={defaultColor} />
      <path d="M11.5703 4.57859C11.5703 4.20242 11.5703 3.85789 11.5668 3.33757C11.5668 3.20398 11.5562 3.06687 11.5316 2.93679C11.4262 2.35671 10.9059 1.92078 10.3152 1.92078C8.11797 1.92078 5.92422 1.92078 3.72695 1.92078C3.64961 1.92078 3.57227 1.92781 3.49844 1.93835C2.94297 2.03328 2.44727 2.5114 2.44727 3.2321C2.44727 3.67859 2.44727 4.12507 2.44727 4.57156C2.44727 4.57507 2.44727 4.57859 2.44727 4.57859V5.55945C2.44727 5.58406 2.44727 5.60867 2.44727 5.63328V5.67546C2.44727 5.91453 2.64062 6.10437 2.87617 6.10437C2.97109 6.10437 3.05547 6.07273 3.12578 6.02351H3.1293C3.16445 6.00945 3.19961 5.97429 3.22422 5.93562C3.25234 5.89695 3.26992 5.85125 3.28398 5.80554C3.29805 5.76335 3.30508 5.72117 3.30508 5.67546V4.57507C3.30508 4.56453 3.30508 4.55398 3.30508 4.54343C3.30508 4.11804 3.30508 3.69265 3.30508 3.26726C3.30508 3.23914 3.30508 3.21453 3.30859 3.1864C3.33672 2.93328 3.49141 2.75046 3.76914 2.77156C3.90977 2.7821 4.05391 2.77507 4.19453 2.77507C6.20898 2.77507 8.22695 2.77859 10.2414 2.77156C10.5613 2.77156 10.702 2.98601 10.7125 3.23562C10.7125 3.28835 10.7125 3.33757 10.7125 3.39031C10.7125 3.85789 10.7125 4.18132 10.7125 4.53289C10.7125 4.54695 10.709 4.56101 10.709 4.57507V5.67546C10.709 5.91453 10.9023 6.10437 11.1379 6.10437C11.377 6.10437 11.5668 5.91101 11.5668 5.67546L11.5703 4.57859Z" fill={defaultColor} />
    </svg>
  )
}

export default ShipIcon
